.tvProgramCardItem {
  font-family: var(--heading-font);
  color: white;
  background-color: var(--background-teaser);
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
  flex: 1;
  padding: 10px;

  &:first-of-type {
    flex-basis: 100%;
    margin-bottom: 0;
    @media screen and (min-width: 60rem) {
      flex-basis: 0;
      margin-bottom: 1rem;
    }
  }

  &:last-of-type {
    display: none;
    @media screen and (min-width: 60rem) {
      display: block;
    }
  }

  @media screen and (min-width: 60rem) {
    border-radius: 0;
  }

  &:not(:last-of-type):after {
    @media screen and (min-width: 60rem) {
      content: '';
      width: 2px;
      background: var(--white);
      position: absolute;
      top: 15%;
      bottom: 15%;
      right: 0;
    }
  }

}

.time {
  color: white;
  font-size: 2rem;
  font-weight: 600;
  display: inline-block;
  line-height: 2.2rem;
  margin-bottom: 8px;

  @media screen and (min-width: 60rem) {
    font-size: 2.125rem;
    line-height: 2.45rem;
  }
}

.text {
  padding: 12px 8px 8px 8px;
  position: relative;
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;

  @media screen and (min-width: 60rem) {
    padding: 16px 11px 25px 11px;
  }
}

.title {
  display: -webkit-box;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: .125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media screen and (min-width: 60rem) {
    margin-bottom: .5rem;
  }

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 8px;
    margin-bottom: 4px;
    @media screen and (min-width: 60rem) {
      margin-bottom: 0;
      width: 1.625rem;
      height: 1.225rem;
    }
  }
}

.titleLong {
  -webkit-line-clamp: 2;
  line-height: 1.4;
  margin-bottom: 9px;
}

.progressLine {
  display: block;
  height: 7px;
  background-color: white;
  border-radius: .33rem;
  position: relative;
  overflow: hidden;
}

.progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: var(--red-alt);
  border-radius: .33rem;
}

.playLive {
  border: 0;
  background-color: initial;
  position: absolute;
  top: 0.875rem;
  right: .25rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
  @media screen and (min-width: 60rem) {
    top: 0.675rem;
  }
}

.chevron {
  border: 0;
  background-color: initial;
  position: absolute;
  top: 1rem;
  right: .25rem;
  cursor: pointer;

  svg {
    fill: white;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.type {
  margin-bottom: .5rem;
  @media screen and (min-width: 60rem) {
    margin-bottom: 1rem;
  }
}

.runningnow {
  cursor: pointer;
}
